body{
    background-color: rgb(240, 250, 243);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.LoginPage {
    background-color: rgb(240, 250, 243);
}

.BodyContainer{
    display: flex;
    justify-content: space-between;
}

.LoginBody{
    margin: 200px 125px;
    height: fit-content;
    width: 50%;
}

.LoginCard{
    padding: 30px;
    width: 380px;
    height: 420px;
    margin: auto;
    background-color: #FFF;
    border: 1px solid #FFFFFF99;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 16px 16px 16px rgba(0, 0, 0, 0.2);
}

.signintext{
    margin-top: 20px;
    font-weight: bold;
}

.paragraph{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    margin-top: 15px;
}

.InputFieldDiv{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.inputfields{
    margin: 15px 0px;
    position: relative;
}

.SignInBtn{
    border: 1px solid #2C2C2C;
    border-radius: 8px;
    color: white;
    background-color: #7F1519;
    padding: 12px;
}

.input {
    border: 1px solid #D9D9D9;
    width: 95%;
    border-radius: 8px;
    height: 36px;
    margin-top: 6px;
    padding-left: 15px;
}
.wrongInput{
    border: 1px solid #7F1519;
    width: 95%;
    border-radius: 8px;
    height: 36px;
    margin-top: 6px;
    padding-left: 15px;
}
.forgotPassword{
    float: right;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 12px;
    color: #7F1519;
    margin-top: 8px;
}
.wrongPassword{
    display: flex;
    justify-content: space-between;
    float: right;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 12px;
    color: #7F1519;
    margin-top: 8px;
    width: 100%;
}
.showHidePassword{
    position: absolute;
    right: 0px;
    top: 40px;
    z-index: 1;
    background: unset !important;
    border: unset !important;
}
.ErrorCard {
    padding: 30px;
    width: 500px;
    height: 260px;
    margin: auto;
    background-color: #FFF;
    border: 1px solid #FFFFFF99;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 16px 16px 16px rgba(0, 0, 0, 0.2);
    position: relative;
}

.backbutton{
    padding: 15px;
    width: 90px;
    border: 1px solid #767676;
    border-radius: 8px;
    font-weight: bold;
}

.BackBtnDiv{
    position: absolute;
    bottom: 50px;
    padding-top: 33px;
    width: 84%;
    left: 25px;
    border-top: 1px solid #E3E3E3;
}