@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
    box-sizing: border-box;
} */

/* body {
    background-color: #E7F5EB;
} */

.achievement-list {
    padding: 0px 22px;
    margin: 0px;
    height: 200px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow: auto !important;
}

.achievement-list.members, .achievement-list.performance-index {
    padding: 0px 4px;
    list-style: none;
}
.achievement-list.members li, .achievement-list.performance-index li {
    margin: 15px 0px;
}
.achievement-list li {
    color: #666666;
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0px;
}
.performance-value {
    background: #1A932E2E;
    padding: 5px 8px;
    border-radius: 16px;
}

.achievement-list::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.performance-card {
    width: 45%;
    margin: 0px 2% 40px;
    border-radius: 16px !important;
    background-color: #E7F5EB !important;
    box-shadow: 0px 12px 12px 0px #0000001F;
}

.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}
